import { LogoutButton } from "@features/authentication";
import { DateSelector } from "@features/ui";
import { HlsPlayer } from "@features/video";
import classNames from "classnames";
import { useEffect, useRef, useState } from "preact/hooks";

const Home = () => {
	const [streamDate, setStreamDate] = useState("");

	useEffect(() => {
		// initialise to yesterday
		const now = new Date();
		now.setDate(now.getDate() - 1);
		setStreamDate(now.toISOString().split("T")[0]);
	}, []);

	const callback = (value) => setStreamDate(value);

	if (streamDate === "") return null;

	// const playerOptions = {
	// 	inactivityTimeout: 0,
	// 	controlBar: {
	// 		skipButtons: {
	// 			backward: 10,
	// 			forward: 10,
	// 		},
	// 	},
	// };

	const playerSrc = {
		src: `https://ring.stuntbaboon.com/stream-${streamDate}/stream.m3u8`,
		type: "application/x-mpegURL",
		withCredentials: true,
	};

	return (
		<div>
			<nav class="flex flex-wrap bg-zinc-800 px-2 py-4 mb-4 shadow-xl">
				<div class="flex items-center flex-shrink-0 text-white mr-4">
					<span class="font-semibold text-xl tracking-tight">
						Ring camera stream
					</span>
				</div>
				<DateSelector selectedDate={streamDate} callback={callback} />
				<div class="block flex-grow flex justify-end w-auto">
					<LogoutButton
						className={classNames(
							"text-sm px-4 py-2.5 leading-none border rounded text-white border-rose-900 bg-rose-700 hover:border-transparent hover:bg-rose-600 mt-0",
						)}
					/>
				</div>
			</nav>

			{/* <section class="player-container">
				<VhsPlayer
					playerOptions={playerOptions}
					playerSrc={playerSrc}
					playerHeight={530}
					playerWidth={940}
				/>
			</section> */}

			<section className={classNames("flex justify-center mx-2")}>
				<HlsPlayer
					playerSrc={playerSrc}
					playerWidth={940}
				/>
			</section>
		</div>
	);
};

export default Home;
